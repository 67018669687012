.conversejs {
    converse-muc.chatroom {

        .chat-status--avatar {
            background: var(--occupants-background-color);
            border: 1px solid var(--occupants-background-color);
        }

        .badge-groupchat {
            background-color: var(--groupchats-header-color);
        }

        .box-flyout {
            .occupants {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                overflow: visible;
                vertical-align: top;
                background-color: var(--occupants-background-color);
                border-left: var(--occupants-border-left);
                padding: 0.5em;
                max-width: 75%;
                min-width: 25%;
                flex: 0 0 25%;

                .occupants-header--title {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 0.5em;

                    .occupants-heading {
                        width: 100%;
                        font-family: var(--heading-font);
                        color: var(--groupchats-header-color-dark);
                        padding-left: 0;
                        margin-right: 0.5em;
                    }

                    .hide-occupants {
                        align-self: flex-end;
                        cursor: pointer;
                        font-size: var(--font-size-small);
                    }
                }

                .fa-user-plus {
                    margin-right: 0.25em;
                }

                .suggestion-box{
                    ul {
                        padding: 0;
                        li {
                            padding: 0.5em;
                        }
                    }
                }
                ul {
                    padding: 0;
                    margin-bottom: 0.5em;
                    overflow-x: hidden;
                    overflow-y: auto;
                    list-style: none;

                    &.occupant-list {
                        overflow-y: auto;
                        flex-basis: 0;
                        flex-grow: 1;
                    }
                    li {
                        cursor: default;
                        display: block;
                        font-size: var(--font-size-small);
                        overflow: hidden;
                        padding: 0.25em 0.25em 0.25em 0;
                        text-overflow: ellipsis;
                        .fa {
                            margin-right: 0.5em;
                        }
                        &.feature {
                            font-size: var(--font-size-tiny);
                        }
                        &.occupant {
                            cursor: pointer;
                            color: var(--link-color);
                            &:hover {
                                color: var(--link-hover-color);
                            }

                            .occupant-nick-badge {
                                display: flex;
                                justify-content: space-between;
                                flex-wrap: wrap;

                                .occupant-badges {
                                    display: flex;
                                    justify-content: flex-end;
                                    flex-wrap: wrap;
                                    flex-direction: row;

                                    span {
                                        height: 1.6em;
                                        margin-right: 0.25rem;
                                    }
                                }
                            }

                            div.row.no-gutters {
                                flex-wrap: nowrap;
                                min-height: 1.5em;
                            }
                            .badge {
                                margin-bottom: 0.125rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
